import React, { useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import { Router, Redirect } from '@gatsbyjs/reach-router'
import { RouteComponentProps } from 'types/reach-router'
import { Helmet } from 'react-helmet'
import Big from 'big.js'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'

import {
  VENTURES_DATA,
  Portfolio,
  getTotalPurchaseValue,
  getTotalCurrentMarketValue,
  getUnrealizedGainAndLoss,
  getUnrealizedGanAndLossPercent,
  getTotalPurchaseValueSum,
  getTotalCurrentMarketValueSum,
} from 'utils'

import { useFetch } from 'hooks'
import { getTickerQuoteSummary } from 'services/api'

import Layout from 'components/Layout'
import LinkBack from 'components/LinkBack'
import TickerLink from 'components/TickerLink'
import TickerChartLink from 'components/TickerChartLink'
import NumberFormatter from 'components/NumberFormatter'

import stealthLogo from 'assets/stealth.svg'

import 'styles/ventures-page.scss'
import classNames from 'classnames'

type Query = {
  venturesPage: {
    title: string
    description: RenderRichTextData<ContentfulRichTextGatsbyReference>
    team: {
      name: string
      credentials: string
      biography: RenderRichTextData<ContentfulRichTextGatsbyReference>
      photo: {
        file: {
          url: string
        }
      }
    }[]
    portfolio: {
      name: string
      url?: string
      description: RenderRichTextData<ContentfulRichTextGatsbyReference>
      logo?: {
        file: {
          url: string
        }
      }
    }[]
    metaTitle?: string
    metaDescription?: string
  }
}

function useFetchPortfolio(): {
  isFetching: boolean
  isFetched: boolean
  portfolio: Portfolio
} {
  const tickers = VENTURES_DATA.map(({ ticker }) => ticker)

  const priceFetches = tickers.map(ticker =>
    useFetch(async () => await getTickerQuoteSummary(ticker)),
  )
  const isFetching = priceFetches.some(({ isFetching }) => isFetching)
  const isFetched = priceFetches.every(({ isFetched }) => isFetched)

  useEffect(() => {
    priceFetches
      .filter(fetch => !fetch.isFetching && !fetch.isFetched)
      .forEach(fetch => fetch.fetch())

    return () => {
      priceFetches.forEach(({ reset }) => reset())
    }
  }, [])

  const prices: { [key: string]: Big | undefined } = priceFetches.reduce(
    (result, { data }) => {
      const { symbol, regularMarketPrice } =
        data?.result?.quoteSummary.result[0].price || {}

      const rawPrice = regularMarketPrice && regularMarketPrice.raw

      if (symbol) {
        return {
          ...result,
          [symbol]: rawPrice && Big(rawPrice),
        }
      }

      return result
    },
    {},
  )

  const assets = Object.entries(prices).reduce<Portfolio['assets']>(
    (result, [ticker, currentMarketPriceRaw]) => {
      const rawData = VENTURES_DATA.find(company => company.ticker === ticker)

      if (!rawData) {
        return result
      }

      const { purchaseDate: rawPurchaseDate, purchasePrice, ...data } = rawData

      const purchaseDate = new Date(rawPurchaseDate)
      const currentMarketPrice =
        currentMarketPriceRaw && Big(currentMarketPriceRaw)

      const totalPurchaseValue = getTotalPurchaseValue(
        purchasePrice,
        data.numberOfShares,
      )

      const totalCurrentMarketValue = getTotalCurrentMarketValue(
        currentMarketPrice,
        data.numberOfShares,
      )

      const unrealizedGainAndLoss = getUnrealizedGainAndLoss(
        totalCurrentMarketValue,
        totalPurchaseValue,
      )

      const unrealizedGainAndLossPercent = getUnrealizedGanAndLossPercent(
        totalPurchaseValue,
        unrealizedGainAndLoss,
      )

      return [
        ...result,
        {
          ...data,
          purchaseDate,
          purchasePrice,
          totalPurchaseValue,
          totalCurrentMarketValue,
          unrealizedGainAndLoss,
          unrealizedGainAndLossPercent,
        },
      ]
    },
    [],
  )

  const totalPurchaseValue = getTotalPurchaseValueSum(assets)
  const totalCurrentMarketValue = getTotalCurrentMarketValueSum(assets)

  const unrealizedGainAndLoss = getUnrealizedGainAndLoss(
    totalCurrentMarketValue,
    totalPurchaseValue,
  )

  const unrealizedGainAndLossPercent = getUnrealizedGanAndLossPercent(
    totalPurchaseValue,
    unrealizedGainAndLoss,
  )

  const portfolio: Portfolio = {
    assets,
    total: {
      totalPurchaseValue,
      totalCurrentMarketValue,
      unrealizedGainAndLoss,
      unrealizedGainAndLossPercent,
    },
  }

  return { isFetching, isFetched, portfolio }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Performance(props: RouteComponentProps) {
  const { isFetching, isFetched, portfolio } = useFetchPortfolio()

  if (isFetching) {
    return <div>Loading…</div>
  }

  if (!isFetched) {
    return <div>No data to display</div>
  }

  return (
    <div className="venture-holdings-portfolio">
      <div className="row">
        {portfolio.assets.map(
          ({
            name,
            ticker,
            purchaseDate,
            numberOfShares,
            totalPurchaseValue,
            totalCurrentMarketValue,
            unrealizedGainAndLoss,
            unrealizedGainAndLossPercent,
          }) => (
            <div key={ticker} className="col-md-6">
              <div className={classNames(['card', 'venture-holdings-card'])}>
                <div className="card-header">
                  <h5>
                    <TickerLink ticker={ticker} />
                  </h5>
                  {name}
                  <TickerChartLink ticker={ticker} />
                </div>
                <div className="card-body">
                  <table>
                    <tbody>
                      <tr>
                        <th>Purchase Date</th>
                        <td>
                          {purchaseDate.toLocaleDateString('en-CA', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                          })}
                        </td>
                      </tr>
                      <tr>
                        <th>Shares</th>
                        <td>
                          <NumberFormatter minimumFractionDigits={0}>
                            {numberOfShares}
                          </NumberFormatter>
                        </td>
                      </tr>
                      <tr>
                        <th>Purchase Value, $</th>
                        <td>
                          <NumberFormatter>
                            {totalPurchaseValue}
                          </NumberFormatter>
                        </td>
                      </tr>
                      <tr>
                        <th>Current Value, $</th>
                        <td>
                          <NumberFormatter>
                            {totalCurrentMarketValue}
                          </NumberFormatter>
                        </td>
                      </tr>
                      <tr>
                        <th>Total, $</th>
                        <td>
                          <NumberFormatter diff>
                            {unrealizedGainAndLoss}
                          </NumberFormatter>
                        </td>
                      </tr>
                      <tr>
                        <th>Total, %</th>
                        <td>
                          <NumberFormatter diff style="percent">
                            {unrealizedGainAndLossPercent}
                          </NumberFormatter>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ),
        )}
        <div className="col-md-12">
          <div
            className={classNames([
              'card',
              'venture-holdings-card',
              'venture-holdings-card-total',
            ])}
          >
            <div className="card-body">
              <table>
                <tbody>
                  <tr>
                    <th>Total Purchase Value, $</th>
                    <td>
                      <NumberFormatter>
                        {portfolio.total.totalPurchaseValue}
                      </NumberFormatter>
                    </td>
                  </tr>
                  <tr>
                    <th>Total Current Value, $</th>
                    <td>
                      <NumberFormatter>
                        {portfolio.total.totalCurrentMarketValue}
                      </NumberFormatter>
                    </td>
                  </tr>
                  <tr>
                    <th>Dividend, $</th>
                    <td>
                      <NumberFormatter diff>
                        {portfolio.total.unrealizedGainAndLoss}
                      </NumberFormatter>
                    </td>
                  </tr>
                  <tr>
                    <th>Rate of Return, %</th>
                    <td>
                      <NumberFormatter diff style="percent">
                        {portfolio.total.unrealizedGainAndLossPercent}
                      </NumberFormatter>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="venture-holdings-table-wrapper">
        <table className="venture-holdings-table">
          <thead>
            <tr>
              <th colSpan={2}>Stock info</th>
              <th>Purchase Date</th>
              <th>Shares</th>
              <th>Purchase Value, $</th>
              <th>Current Value, $</th>
              <th>Total, $</th>
              <th>Total, %</th>
            </tr>
          </thead>
          <tbody>
            {portfolio.assets.map(
              ({
                name,
                ticker,
                purchaseDate,
                numberOfShares,
                totalPurchaseValue,
                totalCurrentMarketValue,
                unrealizedGainAndLoss,
                unrealizedGainAndLossPercent,
              }) => (
                <tr key={ticker}>
                  <td>
                    <TickerLink ticker={ticker} />
                    <p className="venture-holdings-asset-name">{name}</p>
                  </td>
                  <td>
                    <TickerChartLink ticker={ticker} />
                  </td>
                  <td>
                    {purchaseDate.toLocaleDateString('en-CA', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </td>
                  <td>
                    <NumberFormatter minimumFractionDigits={0}>
                      {numberOfShares}
                    </NumberFormatter>
                  </td>
                  <td>
                    <NumberFormatter>{totalPurchaseValue}</NumberFormatter>
                  </td>
                  <td>
                    <NumberFormatter>{totalCurrentMarketValue}</NumberFormatter>
                  </td>
                  <td>
                    <NumberFormatter diff>
                      {unrealizedGainAndLoss}
                    </NumberFormatter>
                  </td>
                  <td>
                    <NumberFormatter diff style="percent">
                      {unrealizedGainAndLossPercent}
                    </NumberFormatter>
                  </td>
                </tr>
              ),
            )}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={4}>&nbsp;</th>
              <th>
                <NumberFormatter>
                  {portfolio.total.totalPurchaseValue}
                </NumberFormatter>
              </th>
              <th>
                <NumberFormatter>
                  {portfolio.total.totalCurrentMarketValue}
                </NumberFormatter>
              </th>
              <th>
                <NumberFormatter diff>
                  {portfolio.total.unrealizedGainAndLoss}
                </NumberFormatter>
              </th>
              <th>
                <NumberFormatter diff style="percent">
                  {portfolio.total.unrealizedGainAndLossPercent}
                </NumberFormatter>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default function VenturesPage() {
  const {
    venturesPage: {
      title,
      description,
      team,
      portfolio,
      metaTitle,
      metaDescription,
    },
  } = useStaticQuery<Query>(graphql`
    query VenturesPage {
      venturesPage: contentfulVenturesPage(title: { eq: "Ventures" }) {
        title
        description {
          raw
        }
        team {
          name
          credentials
          biography {
            raw
          }
          photo {
            file {
              url
            }
          }
        }
        portfolio {
          name
          url
          description {
            raw
          }
          logo {
            file {
              url
            }
          }
        }
        metaTitle
        metaDescription
      }
    }
  `)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Team = (props: RouteComponentProps) => {
    return (
      <>
        {team.map(({ name, credentials, biography, photo }) => (
          <article className="ventures-page__item" key={name}>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="ventures-page__item__image-container">
                  <img
                    className="ventures-page__item__image ventures-page__item__image--photo"
                    src={photo.file.url}
                    alt={`${name} photo`}
                  />
                </div>
              </div>
              <div className="col-12 col-md-8">
                <h3>{name}</h3>
                <p className="ventures-page__item__credentials">
                  {credentials}
                </p>
                {renderRichText(biography)}
              </div>
            </div>
          </article>
        ))}
      </>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Portfolio = (props: RouteComponentProps) => {
    return (
      <>
        {portfolio.map(({ name, url, description, logo }) => (
          <article className="ventures-page__item" key={name}>
            <div className="row">
              <div className="col-12 col-md-4">
                {url ? (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <PortfolioItemLogo logo={logo} name={name} />
                  </a>
                ) : (
                  <PortfolioItemLogo logo={logo} name={name} />
                )}
              </div>
              <div className="col-12 col-md-8">
                <h3>
                  {url ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {name}
                    </a>
                  ) : (
                    name
                  )}
                </h3>
                {renderRichText(description)}
              </div>
            </div>
          </article>
        ))}
      </>
    )
  }

  return (
    <AuthProvider>
      <UserProvider>
        <Layout>
          {(metaTitle || metaDescription) && (
            <Helmet>
              {metaTitle && <title>{metaTitle}</title>}
              {metaDescription && (
                <meta name="description" content={metaDescription} />
              )}
            </Helmet>
          )}
          <div className="row">
            <div className="col-lg-2">
              <LinkBack>&#8592; Go back</LinkBack>
            </div>
            <div className="col-lg-10 col-xl-8">
              <h1>{title}</h1>
              {description && renderRichText(description)}

              <nav className="ventures-page__tabs">
                <Link
                  className="ventures-page__tabs__item"
                  activeClassName="ventures-page__tabs__item--active"
                  to="/ventures/team"
                  replace
                >
                  Team
                </Link>
                <Link
                  className="ventures-page__tabs__item"
                  activeClassName="ventures-page__tabs__item--active"
                  to="/ventures/portfolio"
                  replace
                >
                  Portfolio
                </Link>
                { /* Link should be removed, broken link */}
                {/* <Link
                  className="ventures-page__tabs__item"
                  activeClassName="ventures-page__tabs__item--active"
                  to="/ventures/performance"
                  replace
                >
                  Performance
                </Link> */}
              </nav>

              <Router basepath="/ventures" primary={false}>
                <Team path="team" default />
                <Portfolio path="portfolio" />
                {/* <Performance path="performance" /> */}
                <Redirect noThrow from="/" to="team" />
              </Router>
            </div>
          </div>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}

type PortfolioItemLogoType = {
  logo?: {
    file: {
      url: string
    }
  }
  name: string
}

const PortfolioItemLogo = ({ logo, name }: PortfolioItemLogoType) => {
  return (
    <div className="ventures-page__item__image-container">
      <img
        className="ventures-page__item__image ventures-page__item__image--logo"
        src={logo ? logo.file.url : stealthLogo}
        alt={`${name} logo`}
      />
    </div>
  )
}
